import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import Axios from "axios";
import cogoToast from "cogo-toast";
import moment from "moment";
import React, { useEffect, useState } from "react";
import accountTitles from "../../config/accounts";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6, offset: 1 },
    lg: { span: 6, offset: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
    md: { span: 12, offset: 6 },
    lg: { span: 7, offset: 7 },
  },
};

const api_url = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token_mg_WB");

const ClassModal = (props) => {
  const { formVisible, setFormVisible } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sCourse, setSCourse] = useState(false);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [accountId, setAccountId] = useState(1);
  const [newLmsSelected, setNewLmsSelected] = useState(false); // Track the state of the newLMS switch to display thumbnail input
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    Axios.get(`${api_url}/cb/course`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, courses } = response.data;
      if (error)
        cogoToast.error("Could not load courses", { position: "top-right" });
      else setCourses(courses);
    });

    // Add new API call for teachers
    Axios.get(`${api_url}/teachers`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, teachers } = response.data;
      if (error)
        cogoToast.error("Could not load teachers", { position: "top-right" });
      else setTeachers(teachers);
    });
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    const {
      title,
      date,
      time,
      batch,
      course,
      duration,
      isClassOnNewLms,
      thumbnailUrl,
      description,
      isMustDo,
      extHTML,
      teachers,
    } = values;

    const batches = batch.join();
    if (title) {
      if (isClassOnNewLms) {
        if (!thumbnailUrl || !description) {
          cogoToast.error(
            "Please provide thumbnail URL and description in order to create class for new lms",
            { position: "top-right" }
          );
          return;
        }
      }
      let final_date = moment(date).startOf("day");
      let final_time = moment(time);
      let final_DT = final_date
        .add(final_time.hour(), "hour")
        .add(final_time.minute(), "minute")
        .add(5.5, "hour");
      let data = {
        title,
        batches,
        course,
        duration,
        isClassOnNewLms,
        thumbnailUrl,
        description,
        isMustDo,
        accountId: parseInt(accountId),
        dateTime: final_DT,
        extHTML,
        teachers,
      };
      Axios.post(`${api_url}/class`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const { error, message } = response.data;
          if (error) {
            console.log(error);
            cogoToast.error(message, { position: "top-right" });
          } else {
            cogoToast.success(message, { position: "top-right" }).then(() => {
              setLoading(false);
              setFormVisible(false);
              window.location = "/";
            });
          }
        })
        .catch((error) => {
          console.log(error);
          cogoToast.error("Error in creating event, please try again", {
            position: "top-right",
          });
          setLoading(false);
        });
    } else {
      cogoToast.error("No title provided", { position: "top-right" });
    }
  };

  return (
    <Modal
      visible={formVisible}
      footer={null}
      title="Create Class"
      onCancel={() => {
        setFormVisible(false);
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Date" name="date">
          <DatePicker />
        </Form.Item>
        <Form.Item label="Time" name="time">
          <TimePicker format="HH:mm" />
        </Form.Item>
        <Form.Item label="Course" name="course">
          <Select
            onChange={(e) => {
              setSCourse(false);
              console.log(e.valueOf());
              const c = courses.find((cx) => cx._id === e.valueOf());
              setBatches(c.batches);
              setSCourse(true);
            }}
          >
            {courses
              ? courses.map((course) => {
                  return (
                    <Select.Option key={course._id} value={course._id}>
                      {course.title}
                    </Select.Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item label="Batches" name="batch">
          <Select mode="multiple">
            {batches && sCourse
              ? batches.map((batch) => {
                  return (
                    <Select.Option key={batch._id} value={batch._id}>
                      {batch.title}
                    </Select.Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item label="Teachers" name="teachers">
          <Select mode="multiple">
            {teachers.map((teacher) => (
              <Select.Option key={teacher._id} value={teacher._id}>
                {teacher.name} ({teacher.email})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Duration" name="duration">
          <Input />
        </Form.Item>
        <Form.Item label="Zoom Account" name="zoomAccount">
          <Select
            defaultValue="1"
            options={accountTitles}
            value={accountId}
            onChange={(value) => {
              setAccountId(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Is class on new lms:"
          name="isClassOnNewLms"
          valuePropName="checked"
          className="custom-label"
          initialValue={false}
        >
          <Switch
            className="custom-switch"
            onChange={(checked) => setNewLmsSelected(checked)}
            checked={newLmsSelected}
          />
        </Form.Item>
        {newLmsSelected && (
          <Form.Item label="Thumbnail URL" name="thumbnailUrl">
            <Input />
          </Form.Item>
        )}

        {newLmsSelected && (
          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>
        )}

        {newLmsSelected && (
          <Form.Item label="is Must Do" name="isMustDo" initialValue={false}>
            <Switch className="custom-switch" />
          </Form.Item>
        )}

        <Form.Item label="Extra HTML" name="extHTML">
          <Input />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button
              htmlType="submit"
              size="large"
              loading={loading}
              type="primary"
            >
              Create New Class
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const LinkModal = (props) => {
  const { linkVisible, setLinkVisible } = props;
  const [classes, setClasses] = useState();
  const [link, setLink] = useState();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const { email, class_id } = values;
    const { data } = await Axios.get(`${api_url}/class/link`, {
      params: { email, class_id },
      headers: { Authorization: `Bearer ${token}` },
    });
    const { error, link } = data;
    if (error || !link) cogoToast.error("Error fetching link");
    else setLink(link);
  };

  useEffect(() => {
    Axios.get(`${api_url}/class`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const { error, classes } = response.data;
      if (error) {
        cogoToast.error("Could not load classes", { position: "top-right" });
      } else {
        setClasses(classes);
      }
    });
  }, []);
  return (
    <Modal
      width={600}
      title="Search Link"
      visible={linkVisible}
      footer={null}
      onCancel={() => {
        setLinkVisible(false);
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item label="Email" name="email">
          <Input type="email" />
        </Form.Item>
        <Form.Item label="Class" name="class_id">
          <Select>
            {classes
              ? classes.map((c) => (
                  <Select.Option key={c._id} value={c._id}>
                    {c.title}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button htmlType="submit" size="large" type="primary">
              Search
            </Button>
          </Space>
        </Form.Item>
        <Form.Item label="Link">
          {link ? (
            <span>{link}</span>
          ) : (
            "Fill details and press search to get link"
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EndModal = (props) => {
  const { endVisible, setEndVisible, classId } = props;
  const [notify, setNotify] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { extHTML } = values;
    const { data } = await Axios.patch(
      `${api_url}/class/end`,
      { class_id: classId, extHTML, notify: notify ? "yes" : "no" },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (data.error)
      cogoToast.error("Error ending class", { position: "top-right" });
    else
      cogoToast.success("Ended class and notified", { position: "top-right" });
  };

  return (
    <Modal
      width={600}
      title="End Class"
      visible={endVisible}
      footer={null}
      onCancel={() => {
        setEndVisible(false);
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Form.Item label="Extra HTML" name="extHTML">
          <Input placeholder="(optional)" />
        </Form.Item>

        <Form.Item label="Notify (Email)">
          <Switch
            checked={notify}
            onChange={(e) => setNotify(e.valueOf())}
          ></Switch>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Space size="large">
            <Button htmlType="submit" size="large" type="danger">
              End Class
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { ClassModal, EndModal, LinkModal };
